<template>
    <div class="main">
        <div class="title">用户服务协议</div>
        <p class="placeholder"></p>
        <div>
            欢迎您与蜜蜂查共同签署本《蜜蜂查API产品服务协议》（下称“本协议”）并使用蜜蜂查服务！
        </div>
        <p class="placeholder"></p>
        <div class="bold">提示条款</div>
        <p class="placeholder"></p>
        <div>
            <div>
                协议中条款前所列索引关键词仅为帮助您理解该条款表达的主旨之用，不影响或限制本协议条款的含义或解释。
                <span class="bold underline"
                    >为维护您自身权益，建议您仔细阅读各条款具体表述。</span
                >
            </div>
            <div class="bold underline">
                【审慎阅读】您在同意本协议之前，应当认真阅读本协议。请您务必审慎阅读、充分理解各条款的内容，特别是免除或者限制责任的条款、法律适用和争议解决条款，这些条款将以粗体下划线标识，您应重点阅读。如您对协议有任何疑问，可以向客服和相关业务部门进行咨询。
            </div>
            <div class="bold underline">
                【签约动作】当您阅读并点击同意本协议或以其他方式选择接受本协议后，即表示您已充分阅读、理解并接受本协议的全部内容，并与蜜蜂查达成一致。本协议自您通过网络页面点击确认或以其他方式选择接受本协议之日起成立。阅读本协议的过程中，如果您不同意本协议或其中任何条款约定，请勿进行签约动作。
            </div>
        </div>
        <p class="placeholder"></p>
        <div class="bold">通用服务条款</div>
        <p class="placeholder"></p>
        <div>
            <div class="bold">一、签约主体及协议范围</div>
            <div>
                本服务协议是您与蜜蜂查就您使用蜜蜂查API服务所签署的服务协议。
            </div>
            <p class="placeholder"></p>
            <div class="bold">二、服务内容</div>
            <div>
                本条款中“服务”指：蜜蜂查data.mifengcha.com和data.block.cc（以下单独或统称“蜜蜂查API服务”）所展示的、您申请订购且蜜蜂查同意向您提供的服务。
            </div>
            <p class="placeholder"></p>
            <div class="bold">三、服务费用</div>
            <div>
                3.1.
                服务费用将在您订购页面予以列明公示，您可自行选择具体服务类型并按列明的价格予以支付。您可选择先付费或后付费的服务。
            </div>
            <div>3.2. 先付费：</div>
            <div>
                3.2.1.
                在您付费之后，蜜蜂查才开始为您提供服务。您未在下单后立即付费的，订单失效，订单失效后蜜蜂查与您就服务所达成的合意失效。
            </div>
            <div>
                3.2.2.
                服务期满双方愿意继续合作的，您需要在服务期满前支付续费款项，以使服务得以继续进行。
            </div>
            <div>3.2.3. 免费版本是付费0元的付费套餐。</div>
            <div>
                3.3.
                蜜蜂查保留在您未按照约定支付全部费用之前不向您提供服务和/或技术支持，或者终止服务和/或技术支持的权利。
            </div>
            <div>
                3.4.
                您完全理解蜜蜂查价格体系中所有的赠送服务项目或优惠活动均为蜜蜂查在正常服务价格之外的一次性特别优惠，赠送的服务项目或优惠活动不可折价、冲抵服务价格。
            </div>
            <p class="placeholder"></p>
            <div class="bold">四、您的权利和义务</div>
            <div>
                4.1.
                成功订购服务后，您有权要求蜜蜂查按照本服务协议以及蜜蜂查API网站相关页面所展示的服务说明、技术规范等内容向您提供服务。
            </div>
            <div>4.2. 您订购蜜蜂查API的服务后，您可享受免费的售后服务。</div>
            <div>
                4.3.
                您应按照蜜蜂查API的网站页面提示及本服务协议的约定支付相应服务费用。
            </div>
            <div>4.4. 就蜜蜂查API服务的使用应符合本服务协议。</div>
            <div class="bold underline">
                4.5.
                您须依照《网络安全法》、《互联网信息服务管理办法》等法律法规使用蜜蜂查API服务。
            </div>
            <div class="bold underline">
                4.6.
                您应对您的用户业务数据的来源及内容负责，蜜蜂查提示您谨慎判断数据来源及内容的合法性。您将承担因您的用户业务数据内容违反法律法规、部门规章或国家政策而造成的相应结果及责任。
            </div>
            <div class="bold underline">
                4.7.
                您理解并同意，中华人民共和国的国家秘密受法律保护，您有保守中华人民共和国的国家秘密的义务；您使用蜜蜂查服务应遵守相关保密法律法规的要求，并不得危害中华人民共和国国家秘密的安全。
            </div>
            <div class="bold underline">
                4.8
                您还应仔细阅读并遵守蜜蜂查API在网站页面上展示的相应服务说明、技术规范、使用流程、操作文档等内容（以上简称“操作指引”），依照相关操作指引进行操作。您将承担违反相关操作指引所引起的后果；同时，蜜蜂查郑重提示您，请把握风险谨慎操作。
            </div>
            <p class="placeholder"></p>
            <div class="bold">五、蜜蜂查的权利、义务</div>
            <div>5.1. 蜜蜂查应按照约定提供服务。</div>
            <div>5.2. 服务期限内，蜜蜂查将为您提供如下售后服务：</div>
            <div>
                5.2.1.
                蜜蜂查将提供微信客服、邮件、工单咨询服务，解答您在使用中的问题；
            </div>
            <div class="bold underline">
                5.2.2.
                蜜蜂查将为您提供故障支持服务，您应通过微信客服、邮件、工单申报故障；蜜蜂查将及时就您非人为操作所出现的故障提供支持，但因您的人为原因和/或不可抗力、以及其他非蜜蜂查控制范围内的事项除外。
            </div>
            <div class="bold underline">
                5.3.
                您了解蜜蜂查无法保证其所提供的服务毫无瑕疵，但蜜蜂查承诺不断提升服务质量及服务水平。所以您同意：即使蜜蜂查提供的服务存在瑕疵，但上述瑕疵是当时行业技术水平所无法避免的，其将不被视为蜜蜂查违约。您同意和蜜蜂查一同合作解决上述瑕疵问题。
            </div>
            <div class="bold underline">
                5.4.
                您理解并认可，蜜蜂查将为您提供API服务，尽管蜜蜂查对该等服务经过详细的测试，但并不能保证服务的完全准确性。如果出现服务间断的情况，请及时联系蜜蜂查，获得技术支持。
            </div>
            <p class="placeholder"></p>
            <div class="bold">六、用户业务数据</div>
            <div class="bold underline">
                6.1.
                蜜蜂查理解并认可，您通过蜜蜂查提供的服务，加工、分发以及通过其他方式处理的数据，均为您的用户业务数据，您完全拥有您的用户业务数据。
            </div>
            <div class="bold underline">
                6.2．就用户业务数据，蜜蜂查除执行您的服务要求外，不进行任何未获授权的使用及披露；但以下情形除外：
            </div>
            <div class="bold underline">
                6.2.1．
                在国家有关机关依法查询或调阅用户业务数据时，蜜蜂查具有按照相关法律法规或政策文件要求提供配合，并向第三方或者行政、司法等机构披露的义务；
            </div>
            <div>
                6.2.2． 您和蜜蜂查另行协商一致。
            </div>
            <p class="placeholder"></p>
            <div class="bold">七、服务的开通、终止与变更</div>
            <div>7.1. 先付费的服务：</div>
            <div>
                7.1.1. 您付费后服务即开通，开通后您获得蜜蜂查向您发送API KEY
                即可使用服务，服务期限自开通之时起算；
            </div>
            <div>
                7.1.2.
                本服务以包年包月等固定期限形式售卖，服务期限至订购的期限届满为止；
            </div>
            <div>
                7.1.3.
                您应在服务期限内将服务数量使用完毕，如服务期限届满，您已订购但未使用完毕的服务将被作废且蜜蜂查将不提供其他替代或补充。
            </div>
            <div>7.2. 发生下列情形之一的，服务期限提前终止：</div>
            <div>7.2.1. 双方协商一致提前终止的；</div>
            <div class="bold underline">
                7.2.2.
                您严重违反本协议（包括，您严重违反相关法律法规规定，或您严重违反本协议项下之任一承诺内容等），蜜蜂查有权提前终止服务；
            </div>
            <div class="bold underline">
                7.3．您理解并认可，为技术升级、服务体系升级、或因经营策略调整或配合国家重大技术、法规政策等变化，蜜蜂查不保证永久的提供某种服务，并有权变更所提供服务的形式、规格或其他方面（如服务的价格和计费模式），在终止该种服务或进行此种变更前，蜜蜂查将尽最大努力且提前以网站公告、站内信、邮件或短信等一种或多种方式进行事先通知。
            </div>
            <p class="placeholder"></p>
            <div class="bold">八、违约责任</div>
            <div class="bold underline">
                8.1．您违反本协议中的承诺、保证条款、服务使用规则或义务的任一内容，或蜜蜂查根据其判断认为您的使用行为存在异常的，蜜蜂查均有权就其情节，根据独立判断并单方采取以下措施中的一种或多种：（1）限制、中止使用服务；（2）终止提供服务，终止本协议；（3）追究您的法律责任；（4）其他蜜蜂查认为适合的处理措施。蜜蜂查依据前述约定采取中止服务、终止服务等措施而造成的用户损失将由您承担。
            </div>
            <div class="bold underline">
                8.2．如因您违反有关法律法规或者本协议、相关规则之规定，使蜜蜂查遭受任何损失、受到其他用户、任何第三方的索赔或任何行政管理部门的处罚，您应对蜜蜂查、其他用户或相关第三方的实际损失进行全额赔偿，包括合理的律师费用。
            </div>
            <div>
                8.3．您理解且同意，鉴于计算机、互联网的特殊性，下述情况不属于蜜蜂查违约：
            </div>
            <div>
                8.3.1.
                蜜蜂查在进行系统及服务器配置、维护、升级时，需要短时间中断服务；
            </div>
            <div>8.3.2. 由于Internet上的通路阻塞造成您网站访问速度下降。</div>
            <div class="bold underline">
                8.4．如果因蜜蜂查原因造成您连续72小时不能正常使用服务的，您可终止接受服务，但非蜜蜂查控制之内的原因引起的除外。
            </div>
            <div class="bold underline">
                8.5．在任何情况下，蜜蜂查均不对任何间接性、后果性、惩戒性、偶然性、特殊性的损害，包括您使用蜜蜂查服务而遭受的利润损失承担责任（即使您已被告知该等损失的可能性）。
            </div>
            <div class="bold underline">
                8.6.
                在法律允许的情况下，蜜蜂查在本协议项下所承担的损失赔偿责任不超过就该服务过往12个月所缴纳的服务费用的总和。
            </div>
            <p class="placeholder"></p>
            <div class="bold">九、通知</div>
            <div class="bold underline">
                9.1.
                您在使用蜜蜂查服务时，您应该向蜜蜂查提供真实有效的联系方式（包括您的电子邮件地址、联系电话、联系地址等），对于联系方式发生变更的，您有义务及时更新有关信息，并保持可被联系的状态。您接收站内信、系统消息的会员账号（包括子账号），也作为您的有效联系方式。
            </div>
            <div>
                9.2．
                蜜蜂查将向您的上述联系方式的其中之一或其中若干向您送达各类通知，而此类通知的内容可能对您的权利义务产生重大的有利或不利影响，请您务必及时关注。
            </div>
            <div>
                9.3.
                蜜蜂查通过上述联系方式向您发出通知，其中以电子的方式发出的书面通知，包括公告，向您提供的联系电话发送手机短信，向您提供的电子邮件地址发送电子邮件，向您的账号发送系统消息以及站内信信息，在发送成功后即视为送达；以纸质载体发出的书面通知，按照提供联系地址交邮后的第五个自然日即视为送达。
            </div>
            <div class="bold underline">
                9.4.
                你应当保证所提供的联系方式是准确、有效的，并进行实时更新。如果因提供的联系方式不确切，或不及时告知变更后的联系方式，使法律文书无法送达或未及时送达，将由您承担由此可能产生的法律后果。
            </div>
            <p class="placeholder"></p>
            <div class="bold">十、不可抗力</div>
            <div>
                10.1.
                因不可抗力或者其他意外事件，使得本服务条款的履行不可能、不必要或者无意义的，遭受不可抗力、意外事件的一方不承担责任。
            </div>
            <div>
                10.2.
                不可抗力、意外事件是指不能预见、不能克服并不能避免且对一方或双方当事人造成重大影响的客观事件，包括自然灾害如洪水、地震、瘟疫流行等以及社会事件如战争、动乱、政府行为、电信主干线路中断、黑客、网路堵塞、电信部门技术调整和政府管制等。
            </div>
            <p class="placeholder"></p>
            <div class="bold">十一、法律适用及争议解决</div>
            <div>
                11.1.
                本协议之订立、生效、解释、修订、补充、终止、执行与争议解决均适用中华人民共和国大陆法律；如法律无相关规定的，参照商业惯例及/或行业惯例。
            </div>
            <div>
                11.2.
                您因使用蜜蜂查服务所产生及与蜜蜂查服务有关的争议，由蜜蜂查与您协商解决。
            </div>
            <p class="placeholder"></p>
            <div class="bold">十二、附则</div>
            <div>
                12.1．本协议的附件，以及蜜蜂查在蜜蜂查网站相关页面上的服务说明、价格说明和您确认同意的订购页面（包括产品的专用条款、服务说明、操作文档等）均为本协议不可分割的一部分。如遇不一致之处，以（1）服务说明、价格说明、其他订购页面，（2）专用条款和附件，（3）本协议通用条款的顺序予以适用。
            </div>
            <div>
                12.2.
                如本协议内容发生变动，蜜蜂查应通过提前30天在蜜蜂查网站的适当版面公告向您提示修改内容；如您继续使用蜜蜂查服务，则视为您接受蜜蜂查所做的相关修改。
            </div>
            <div>
                12.3.
                蜜蜂查有权经提前通知将本协议的权利义务全部或者部分转移给的蜜蜂查关联公司。
            </div>
            <div>
                12.4.
                蜜蜂查于您过失或违约时放弃本协议规定的权利，不应视为其对您的其他或以后同类之过失或违约行为弃权。
            </div>
            <div>
                12.5.
                本协议任一条款被视为废止、无效或不可执行，该条应视为可分的且并不影响本协议其余条款的有效性及可执行性。
            </div>
            <div>
                12.6
                本协议项下之保证条款、法律适用及争议解决条款等内容，不因本协议的终止而失效。
            </div>
        </div>
    </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.main {
    padding: 50px 400px 100px;
    font-size: 16px;
    line-height: 1.8;
    div {
        margin: 5px 0 10px 0;
    }
    .title {
        text-align: center;
        font-size: 18px;
        font-weight: bold;
    }
    .placeholder {
        height: 25px;
    }
    .bold {
        font-weight: 700;
    }
    .underline {
        text-decoration: underline;
    }
}
</style>
